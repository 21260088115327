.contenedor {
    display: flex;
    justify-content: center;
  }
  
  /* medidas para corregir: 991, 767, 575, 443 */
  @media (max-width: 767px) {
    .contenedor {
        display: flex;
        flex-direction: column;
    }
  }
  
  /* Estilos para pantallas grandes (PC) */
@media (min-width: 768px) {
  .table-container {
    display: block;
  }
  .acordeon-container {
    display: none;
  }
}

/* Estilos para pantallas pequeñas (Móviles) */
@media (max-width: 767px) {
  .table-container {
    display: none;
  }
  .acordeon-container {
    display: block;
  }
}



.eyeIcon {
    height: 30%;
    right: 3%;
    align-self: center;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
  }
  
.ocultar-div{
  display:block
}

  @media screen and (max-width: 760px){
    .ocultar-div{
      display:none
    }
  }

.image-upload > input
  {
      display: none;
  }
  
.image-upload img
  {
      cursor: pointer;
  }

.passwordContainer {
    display: flex;
    position: relative;
    margin-bottom: 1.5rem;
}

/* .modalBase {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  max-height: 100vh;
  overflow: auto;
} */

p.clasificacion {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

p.clasificacion input {
  position: absolute;
  top: -100px;
}

p.clasificacion label {
  float: right;
  color: #333;
}


p.clasificacion input:checked ~ label {
  color: #dd4;
}

p.clasificacion input:hover ~ label {
  pointer-events: none;
}

#nav {
  margin-top: 0 !important;
  transition: all 0.3s ease-in;
}
.navigation ul {
  width: 100%;
}
.navigation ul li {
  /* font-family: 'RobotoBoldCondensed'; */
  margin: 0;
  /* width: 14.285%; */
  width: 100%;
}
.navigation ul li a {
  border: 0;
  border-bottom: 6px solid transparent;
  color: #000000;
  padding: 10px 0;
  text-align: center;
  font-size: 17px;
}
.responsive ul li a {
  border: 0;
  color: #000000;
  text-transform: uppercase;
  background: transparent;
}

.navigation ul li a.hogar {
  border-color: #e1802d;
}
.navigation ul li a.cocina {
  border-color: #a21f13;
}
.navigation ul li a.desayuno {
  border-color: #ecb74c;
}
.navigation ul li a.climatizacion {
  border-color: #008658;
}
.navigation ul li a.linea-blanca {
  border-color: #1d70a7;
}
.navigation ul li a.agua-caliente {
  border-color: #2e3182;
}
.navigation ul li a.movilidad {
  border-color: #000;
}

.navigation ul li a:hover,
.navigation ul li > a.selected,
.navigation ul li.active > a {
    border-top: 0 !important;
}

.navigation ul li a.hogar:hover,
.navigation ul li > a.hogar.selected,
.navigation ul li.active > a.hogar,
.responsive ul li a.hogar,
.sub-4 {
    background: #e1802d;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.cocina:hover,
.navigation ul li > a.cocina.selected,s
.navigation ul li.active a.cocina,
.responsive ul li a.cocina,
.sub-2 {
    background: #a21f13;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.desayuno:hover,
.navigation ul li > a.desayuno.selected,
.navigation ul li.active > a.desayuno,
.responsive ul li a.desayuno,
.sub-1 {
    background: #ecb74c;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.climatizacion:hover,
.navigation ul li > a.climatizacion.selected,
.navigation ul li.active > a.climatizacion,
.responsive ul li a.climatizacion,
.sub-6 {
    background: #008658;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.linea-blanca:hover,
.navigation ul li > a.linea-blanca.selected,
.navigation ul li.active > a.linea-blanca,
.responsive ul li a.linea-blanca,
.sub-5 {
    background: #1d70a7;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.agua-caliente:hover,
.navigation ul li > a.agua-caliente.selected,
.navigation ul li.active > a.agua-caliente,
.responsive ul li a.agua-caliente,
.sub-7 {
    background: #2e3182;
    font-size: 18px;
    color: #ffffff;
}
.navigation ul li a.movilidad:hover,
.navigation ul li > a.movilidad.selected,
.navigation ul li.active > a.movilidad,
.responsive ul li a.movilidad,
.sub-8 {
    background: #000;
    font-size: 18px;
    color: #ffffff;
}

/* Estilos para dispositivos móviles */
@media (max-width: 767px) {
  body {
    /* Aplica estilos específicos para dispositivos móviles */
    zoom: 100%;
  }
}

/* Estilos para pantallas de escritorio */
@media (min-width: 768px) {
  body {
    /* Aplica estilos específicos para pantallas de escritorio */
    zoom: 100%;
  }
}


.dropdown-menu-tabla { inset: auto auto auto -150% !important; }


/* Estilo predeterminado para la imagen */
.imagen {
  width: 20rem;
  transform: rotate(350deg);
  position: relative;
  z-index: -1;
}

/* Media query para pantallas en formato celular */
@media (max-width: 768px) {
  .imagen {
    width: 9rem;
  }
}

